@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-medium-image-zoom/dist/styles.css";

@media screen and (min-width: 780px) {
  body {
    zoom: 100%;
  }
}
